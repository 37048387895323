import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DateTimeInputProps } from './date-time-input';
import FormElement from '~ui/FormElement';
import "react-datepicker/dist/react-datepicker.css?__remix_sideEffect__";
import Datepicker from 'react-tailwindcss-datepicker';

export const DateTimeInput = React.forwardRef<
  HTMLInputElement,
  DateTimeInputProps>(

  (
  {
    name,
    value,
    label,
    error,
    required,
    labelBgColor,
    helperText,
    description,
    containerClassName,
    onChange,
    children,
    ...props
  },
  ref) =>
  {


    const [val, setVal] = useState({
      startDate: value ? new Date(String(value)) : new Date(),
      endDate: new Date()
    });

    const handleChange = useCallback(
      (date: Date | null) => {
        setVal({
          startDate: date || new Date(),
          endDate: new Date()
        });
        onChange!(date?.toISOString() || '');
      },
      [onChange]
    );


    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        description={description}
        className={containerClassName}>

        <Datepicker
          value={val}
          onChange={(newValue) => handleChange(newValue?.startDate ? new Date(newValue.startDate) : null)}
          useRange={false}
          asSingle={true}
          displayFormat={'MM/DD/YYYY'}
          inputClassName="border-gray-300 focus:border-primary-600 dark:border-gray-500 dark:focus:border-slate-300 bg-white text-gray-900 dark:bg-slate-900 dark:focus:bg-slate-900 dark:text-slate-400 w-full py-2.5 text-sm rounded-md  px-2.5" />

        <input
          type="hidden"
          name={name}
          value={val?.startDate.toISOString()}
          ref={ref} />

      </FormElement>);

  }
);

export default DateTimeInput;